import PropTypes from 'prop-types'
import cns from 'classnames'

import { useRouter } from 'next/router'

import styles from './brandsNew.module.scss'

function BrandsNew({ isABTest, pageId, data = [], title, fullWidth }) {
  const { locale } = useRouter()

  const localBrands = data.filter(brand => brand.locale.includes(locale))

  // странички для автопостинга, в которых не меняются картинки
  const autopostingPages = [
    'autoposting-instagram',
    'autoposting-odnoklassniki',
    'avtoposting-vk',
    'avtoposting-v-telegram'
  ]

  const currentPageId = autopostingPages.includes(pageId) ? 'autoposting' : pageId

  return (
    <div className={styles.root}>
      {title && <p className={cns(styles.title, pageId === 'avtoposting-vk' && styles.titleAutopostingVK)}>{title}</p>}

      <div className={cns(
        styles.scrollable,
        locale === 'br' && styles.scrollable_brazil,
        isABTest && styles['scrollable__test'],
      )}>
        <ul className={cns(styles.list, fullWidth && styles.list_fullWidth)}>
          {localBrands.map((brand, i) => (
            <li key={i}>
              <img
                className={styles.img}
                src={`/images/brands/${currentPageId}/${brand.img}.png`}
                srcSet={`/images/brands/${currentPageId}/${brand.img}.png 1x, /images/brands/${currentPageId}/${brand.img}@2x.png 2x`}
                alt={brand.alt}
                width={200}
                height={200}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

BrandsNew.propTypes = {
  isABTest: PropTypes.bool,
  data: PropTypes.array,
  fullWidth: PropTypes.bool,
  pageId: PropTypes.string,
  title: PropTypes.string,
}

export default BrandsNew
